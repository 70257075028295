import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanLoad,
  Params,
  Route,
  Router,
  UrlTree,
} from '@angular/router';
import { Subject, takeUntil } from 'rxjs';

import {
  ANALYZING_MODES,
  MODULES,
  REFERENCE_PERMISSION_ACTIONS,
  USER_TYPES,
} from '@shared/constants';
import { generatePermissionKey } from '@shared/utils';

import { LoggedUserService } from '../../auth/services';
import { ReferenceCategoryService } from '../../references/services';

@Injectable({
  providedIn: 'root',
})
export class PermissionGuard implements CanLoad, CanActivate {
  private clearAuthListening = new Subject<void>();

  constructor(
    private router: Router,
    private location: Location,
    private loggedUserService: LoggedUserService,
    private referenceCategoryService: ReferenceCategoryService
  ) {}

  private checkPermission(
    _allowedPermissions?: string[],
    _analyzingMode = ANALYZING_MODES.EVERY,
    module?: MODULES,
    params?: Params
  ): Promise<boolean | UrlTree> {
    let allowedPermissions: string[] = [...(_allowedPermissions ?? [])];
    let analyzingMode = _analyzingMode;

    return new Promise((resolve) => {
      const resolveAccess = (accessGranted: boolean) => {
        if (!accessGranted) {
          const destination = this.router
            .getCurrentNavigation()
            ?.extractedUrl?.toString();
          this.router
            .navigateByUrl('/app/access-denied', { skipLocationChange: true })
            .then(() => {
              this.location.replaceState(destination);
            });
        }

        resolve(accessGranted);
      };

      this.loggedUserService.dataStore
        .pipe(takeUntil(this.clearAuthListening))
        .subscribe(async (data) => {
          if (data) {
            // Allow everything to EL Admin
            if (data?.user_type === USER_TYPES.EL_ADMIN) {
              return resolveAccess(true);
            }

            if (module === MODULES.REFERENCES) {
              analyzingMode = ANALYZING_MODES.SOME;

              const category =
                await this.referenceCategoryService.getCategoryById(
                  params.categoryId
                );

              if (!category) {
                return resolveAccess(false);
              } else if (category.is_public) {
                return resolveAccess(true);
              }

              allowedPermissions = allowedPermissions.map((permission) =>
                generatePermissionKey(
                  MODULES.REFERENCES,
                  permission as REFERENCE_PERMISSION_ACTIONS,
                  category.name
                )
              );
            }

            // allow if allowed permissions are not defined
            if (!allowedPermissions || allowedPermissions.length === 0) {
              return resolveAccess(true);
            }

            if (
              analyzingMode === ANALYZING_MODES.EVERY &&
              allowedPermissions.every((permission) =>
                data.connection.permissions.includes(permission)
              )
            ) {
              return resolveAccess(true);
            } else if (
              analyzingMode === ANALYZING_MODES.SOME &&
              allowedPermissions.some((permission) =>
                data.connection.permissions.includes(permission)
              )
            ) {
              return resolveAccess(true);
            } else {
              console.log(
                `[DEBUG PermissionGuard]: Navigated to the Access Denied page because of the permission mismatch`
              );

              console.log('Required Permissions:', allowedPermissions);
              console.log('Current Permissions:', data.connection.permissions);

              return resolveAccess(false);
            }
          } else {
            return resolveAccess(false);
          }
        });
    });
  }

  canLoad(route: Route): Promise<boolean | UrlTree> {
    this.clearAuthListening.next();

    return this.checkPermission(
      route.data?.allowedPermissions,
      route.data?.analyzingMode ?? undefined,
      route.data?.module,
      {}
    );
  }

  canActivate(route: ActivatedRouteSnapshot): Promise<boolean | UrlTree> {
    this.clearAuthListening.next();

    return this.checkPermission(
      route.data?.allowedPermissions,
      route.data?.analyzingMode ?? undefined,
      route.data?.module,
      route.params
    );
  }
}
